import { render, staticRenderFns } from "./VaInput.vue?vue&type=template&id=66a9d0cb&scoped=true&"
import script from "./VaInput.vue?vue&type=script&scoped=true&lang=js&"
export * from "./VaInput.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./VaInput.vue?vue&type=style&index=0&id=66a9d0cb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a9d0cb",
  null
  
)

export default component.exports